/* global google */
import axios from "axios";
import { useEffect, useState } from "react";
import Tabs from "../../components/tabs";
import LocalesComponent from "../../components/locales";
import { LayoutCss, TitleCss } from "./index.css";

const LOCALE = "locale";
const API_URL = `${process.env.REACT_APP_BASE_URL}/api`;
axios.defaults.baseURL = API_URL;

function Home() {
  // const defaultLocale = localStorage.getItem(LOCALE) || "en";
  const defaultLocale = "en";
  const [menuItems, setMenuItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [locales, setLocales] = useState([]);
  const [activeLocale, setActiveLocale] = useState(defaultLocale);

  useEffect(() => {
    // Get the locales when app loads
    axios.get(`/i18n/locales`).then((res) => {
      setLocales(res.data);
    });
  }, []);

  useEffect(() => {
    // Get all items with the proper locale
    axios.get(`/menu-items/?locale=${activeLocale}&populate=*`).then((res) => {
      setMenuItems(res.data.data);
    });
    // Get all categories with the proper locale
    axios.get(`/categories?locale=${activeLocale}&populate=*`).then((res) => {
      setCategories(res.data.data);
    });
  }, [activeLocale]);

  const handleLocaleChange = (loc) => {
    setActiveLocale(loc.code);
    localStorage.setItem(LOCALE, loc.code);
  };

  return (
    <LayoutCss title="homepage">
      <TitleCss>Bamboo Houses</TitleCss>
      {process.env.REACT_APP_USE_GOOGLE_TRANSLATE === "true" ? (
        <div id="google_translate_element"></div>
      ) : (
        <LocalesComponent locales={locales} activeLocale={activeLocale} handleLocaleChange={handleLocaleChange} />
      )}
      <Tabs categories={categories} menuItems={menuItems} />
    </LayoutCss>
  );
}

export default Home;
