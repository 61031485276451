import styled from "styled-components";

export const TabContentCss = styled.div`
  padding: 20px 0;
`;
export const TabTitleCss = styled.h4`
  font-weight: bold;
  position: relative;
  text-transform: capitalize;
  margin: 0 0 25px;
  &::after {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 2px;
    background: black;
  }
`;
