import { Combobox } from "@headlessui/react";
import styled from "styled-components";

export const ComboboxWrapperCss = styled.div`
  position: relative;
`;

export const SelectLocaleButtonCss = styled(Combobox.Button)`
  background: white;
  border-radius: 50px;
  border: 1px solid black;
  padding: 10px 15px;
  color: blue;
`;

export const OptionsCss = styled(Combobox.Options)`
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border-radius: 5px;
  z-index: 1;
  border: 1px solid lightgrey;
  padding: 15px 15px;
  margin: 5px 0 0 0;
  max-height: 30vh;
  overflow: auto;
`;

export const OptionCss = styled(Combobox.Option)`
  list-style: none;
  padding: 5px 0 5px 20px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const InputCss = styled(Combobox.Input)`
  border: 1px solid lightgrey;
  padding: 5px 7px;
  margin-bottom: 10px;
`;

export const OptionNameCss = styled.p`
  margin: 0;
  padding: 0;
`;

export const OptionCheckMarkWrapperCss = styled.div`
  position: absolute;
  left: 0;
  top: 7px;
  width: 15px;
  svg {
    width: 100%;
    height: 100%;
  }
`;
