import { priceWithCurrency } from "../../helpers/currency";
import {
  ProductWrapperCss,
  ImageWrapperCss,
  ProductContentCss,
  RowCss,
  TitleCss,
  PriceCss,
  DescriptionCss,
  ImagePlaceholderCss,
} from "./index.css";

const ProductView = ({ product }) => {
  const productImage = product?.images?.data?.[0]?.attributes?.url;

  return (
    <ProductWrapperCss>
      <ImageWrapperCss>
        {productImage ? (
          <img src={process.env.REACT_APP_BASE_URL + productImage} alt={product?.name} />
        ) : (
          <ImagePlaceholderCss />
        )}
      </ImageWrapperCss>
      <ProductContentCss>
        <RowCss>
          <TitleCss>{product?.name}</TitleCss>
          <PriceCss>{priceWithCurrency(product?.price, product?.currency)}</PriceCss>
        </RowCss>
        <RowCss>
          <DescriptionCss>{product?.description}</DescriptionCss>
        </RowCss>
      </ProductContentCss>
    </ProductWrapperCss>
  );
};

export default ProductView;
