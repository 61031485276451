export const currencyTypes = {
  "turkish-lira": "₺",
  euro: "€",
  pound: "£",
  dolar: "$",
};

export const priceWithCurrency = (price, currency) => {
  const currencyValue =
    currencyTypes[currency || process.env.REACT_APP_DEFAULT_CURRENCY] || currencyTypes["turkish-lira"];

  return `${price}${currencyValue}`;
};
