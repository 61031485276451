import { Combobox } from "@headlessui/react";
import { useEffect, useState } from "react";
import {
  SelectLocaleButtonCss,
  ComboboxWrapperCss,
  InputCss,
  OptionsCss,
  OptionCss,
  OptionNameCss,
  OptionCheckMarkWrapperCss,
} from "./index.css";
import { CheckIcon } from "@heroicons/react/solid";

const Locales = ({ locales = [], activeLocale = null, handleLocaleChange }) => {
  const [selectedLocale, setSelectedLocale] = useState(null);
  const [query, setQuery] = useState("");

  useEffect(() => {
    const active = locales.find((l) => l.code === activeLocale);
    setSelectedLocale(active);
  }, [locales]);

  const handleChange = (locale) => {
    setSelectedLocale(locale);
    handleLocaleChange(locale);
    setQuery("");
  };

  const filteredPeople =
    query === "" ? locales : locales.filter((locale) => locale.name.toLowerCase().includes(query.toLowerCase()));

  return (
    <ComboboxWrapperCss>
      <Combobox value={selectedLocale} onChange={handleChange}>
        <SelectLocaleButtonCss>{selectedLocale?.name}</SelectLocaleButtonCss>
        <OptionsCss>
          <InputCss onChange={(event) => setQuery(event.target.value)} />

          {filteredPeople.length === 0 && query !== "" ? (
            <div>Nothing found.</div>
          ) : (
            filteredPeople.map((locale) => (
              <OptionCss key={locale.code} value={locale}>
                {({ selected, active }) => (
                  <>
                    <OptionNameCss>{locale.name}</OptionNameCss>
                    {selected ? (
                      <OptionCheckMarkWrapperCss active={active}>
                        <CheckIcon aria-hidden="true" />
                      </OptionCheckMarkWrapperCss>
                    ) : null}
                  </>
                )}
              </OptionCss>
            ))
          )}
        </OptionsCss>
      </Combobox>
    </ComboboxWrapperCss>
  );
  // return (
  //   <>
  //     {locales.map((locale, i) => {
  //       return (
  //         <p onClick={() => handleLocaleChange(locale)} key={i}>
  //           {locale.name}
  //         </p>
  //       );
  //     })}
  //   </>
  // );
};

export default Locales;
