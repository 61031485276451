import { useEffect, useState } from "react";
import ProductView from "../productView";
import { TabContentCss, TabTitleCss } from "./index.css";

const TabContent = ({ category, menuItems }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const productsFromCategory = menuItems.filter((el) => {
      return el?.attributes?.categories?.data?.find((cat) => cat.id === category.id);
    });
    setProducts(productsFromCategory);
  }, [category, menuItems]);

  const title = category?.attributes?.title || "";
  return (
    <TabContentCss id={`${title.toLowerCase()}-tab`}>
      <TabTitleCss>{title}</TabTitleCss>
      {products.map((product) => (
        <ProductView key={product?.id} product={product?.attributes} />
      ))}
    </TabContentCss>
  );
};

export default TabContent;
