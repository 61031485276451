import styled from "styled-components/macro";

export const ProductWrapperCss = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ImageWrapperCss = styled.div`
  max-width: 85px;
  height: 85px;
  border-radius: 7px;
  overflow: hidden;
  flex: 1;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const ProductContentCss = styled.div`
  flex: 1;
`;

export const RowCss = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  width: 100%;
`;

export const TitleCss = styled.p`
  margin: 0 0 10px;
  font-weight: bold;
  text-transform: capitalize;
  font-size: 16px;
`;

export const PriceCss = styled.p`
  margin: 0;
  color: black;
  font-size: 16px;
`;

export const DescriptionCss = styled.p`
  margin: 0;
  color: #505050;
  font-size: 15px;
`;

export const ImagePlaceholderCss = styled.div`
  width: 85px;
  height: 85px;
  border-radius: 7px;
  overflow: hidden;
  flex: 1;
  background: lightgrey;
`;
