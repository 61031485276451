import styled from "styled-components";

export const TabListCss = styled.div`
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, #ffffff 40%, rgba(255, 255, 255, 0) 100%);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  padding: 25px 20px 15px;
  overflow: auto;
`;

export const TabCss = styled.div`
  border-radius: 50px;
  padding: 10px 15px;
  background: white;
  border: 1px solid black;
  display: block;
  white-space: nowrap;

  &:focus-visible {
    outline: none;
  }
`;
