import TabContent from "../tabContent";
import { TabListCss, TabCss } from "./index.css";

function Tabs({ categories = [], menuItems = [] }) {
  return (
    <div>
      <div>
        {categories.map((category) => (
          <TabContent key={category.id} category={category} menuItems={menuItems} />
        ))}
      </div>
      <TabListCss>
        {categories.map((category) => (
          <TabCss key={category.id}>
            <a href={`#${category.attributes.title.toLowerCase()}-tab`}>{category.attributes.title}</a>
          </TabCss>
        ))}
      </TabListCss>
    </div>
  );
}

export default Tabs;
