import styled from "styled-components";

export const LayoutCss = styled.div`
  padding: 20px;
  margin-bottom: 70px;
`;

export const TitleCss = styled.h1`
  text-align: center;
  margin: 15px 0 15px;
  font-weight: normal;
`;
